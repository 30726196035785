import { lazy, useMemo } from "react";
const RedirectRoute = lazy(
  () => import("./redirect-route-Bo5yVCsI.js").then((module) => ({
    default: module.RedirectRoute
  }))
);
const useRedirectRoute = () => {
  return useMemo(
    () => ({
      "redirect-route": {
        component: RedirectRoute
      }
    }),
    []
  );
};
export {
  useRedirectRoute
};
